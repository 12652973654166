import { useEffect, useState } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import instance from "../services/networks";

const useAuth = () => {
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<any | null>(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      // Загрузка пользователя по токену
      axios
        .get(`user`, {
          headers: { Authorization: `Bearer ${storedToken}` },
        })
        .then((response) => {
          setUser(response.data);
          navigate("/sessions");
        })
        .catch((error) => console.error(error));
    }
  }, []);

  const login = async (username: string, password: string) => {
    const response = await instance.post(`login`, {
      username,
      password,
    });
    if (response.data.token) {
      setToken(response.data.token);
      localStorage.setItem("token", response.data.token);
      // Загрузка пользователя по токену
      instance
        .get(`user`, {
          headers: { Authorization: `Bearer ${response.data.token}` },
        })
        .then((response) => {
          setUser(response.data);
          navigate("/sessions");
        })
        .catch((error) => console.error(error));
    } else {
      throw new Error("Error loign");
    }
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
  };

  return { token, user, login, logout };
};

export default useAuth;
