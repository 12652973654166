exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-panel-tsx": () => import("./../../../src/pages/admin-panel.tsx" /* webpackChunkName: "component---src-pages-admin-panel-tsx" */),
  "component---src-pages-battle-tsx": () => import("./../../../src/pages/battle.tsx" /* webpackChunkName: "component---src-pages-battle-tsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-sessions-tsx": () => import("./../../../src/pages/sessions.tsx" /* webpackChunkName: "component---src-pages-sessions-tsx" */)
}

