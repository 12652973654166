import "./src/styles/index.scss";
import React from "react";
import { RaceProvider } from "./src/contexts/RaceContext";
import { navigate } from "gatsby";
import Header from "./src/components/Header/Header"; // Adjust the path as necessary

export const wrapPageElement = ({ element }) => {
  return (
    <RaceProvider>
      <Header /> {element}
    </RaceProvider>
  );
};
export const onInitialClientRender = () => {
  const token = localStorage.getItem("token"); // Получаем токен
  if (!token) {
    navigate("/"); // Перенаправляем на главную страницу, если токен отсутствует
  }
};
