import axios from "axios";

// export const BASE_URL_SERVER = "http://62.113.103.127:3000"; // Замените на ваш API URL
export const BASE_URL_SERVER = `${process.env.MODE === "DEV" ? "http://page-store.ru" : ""}`; // Замените на ваш API URL
export const API_URL = `${BASE_URL_SERVER}/api/`; // Замените на ваш API URL
const instance = axios.create({
  baseURL: API_URL,
});
console.log("process.env", process.env.MODE);
// Добавляем интерцептор запросов
instance.interceptors.request.use(
  (config) => {
    // Проверяем, есть ли токен в локальном хранилище или глобальной переменной
    const token = localStorage.getItem("token"); // Пример получения токена
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
