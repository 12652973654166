import { ICharacter, IClasses, IRaces } from "../models/models";
import instance from "./networks";
import { navigate } from "gatsby";

export const getRaces = async (): Promise<IRaces[]> => {
  try {
    const response = await instance.get(`data/races`);
    return response.data;
  } catch (error) {
    console.error("Error fetching races:", error);
    throw error;
  }
};
export const getClasses = async (): Promise<IClasses[]> => {
  try {
    const response = await instance.get(`data/classes`);
    return response.data;
  } catch (error) {
    console.error("Error fetching races:", error);
    throw error;
  }
};
export const createCharacter = async (
  idRace: number,
  idClass: number,
  session_id: number,
): Promise<IClasses[]> => {
  try {
    await instance.post(`create`, {
      idRace,
      idClass,
      session_id,
    });
    navigate("/profile");
  } catch (error) {
    console.error("Error fetching races:", error);
    throw error;
  }
};
export const updateCharacter = async (
  data: ICharacter,
): Promise<ICharacter[]> => {
  try {
    await instance.put(`updateCharacter`, {
      ...data,
    });
  } catch (error) {
    console.error("Error fetching races:", error);
    throw error;
  }
};
