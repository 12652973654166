import React from "react";
import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { navigate } from "gatsby";
import LogoutIcon from "@mui/icons-material/Logout";

const Header: React.FC<any> = () => {
  const handleExit = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <AppBar position="static" color="primary" style={{ marginBottom: "20px" }}>
      <Toolbar>
        {/*<IconButton edge="start" color="inherit" aria-label="menu">*/}
        {/*  <MenuIcon />*/}
        {/*</IconButton>*/}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Акт судьбы v1.0.1
        </Typography>
        <IconButton
          color="inherit"
          onClick={() => {
            navigate("/profile");
          }}
        >
          <AccountCircleIcon />
        </IconButton>
        <Button color="inherit" onClick={handleExit}>
          <LogoutIcon />
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
