import React, { createContext, useContext, useEffect, useState } from "react";
import { IClasses, IRaces } from "../models/models";
import { getClasses, getRaces } from "../services/services";
import useAuth from "../hooks/useAuth";

interface RaceContextProps {
  races: IRaces[];
  classes: IClasses[];
  loading: boolean;
  error: string | null;
  setStateContext: (obj: any) => void;
  // fetchRaces: () => void;
}

const RaceContext = createContext<RaceContextProps>({
  races: [],
  classes: [],
  loading: true,
  error: null,
  setStateContext: (obj: any) => {},
  // fetchRaces: () => {},
});

const RaceProvider: React.FC = ({ children }) => {
  const [races, setRaces] = useState<IRaces[]>([]);
  const [classes, setClasses] = useState<IClasses[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { token, user } = useAuth(); //TODO
  const { setStateContext } = useContext(RaceContext);
  const storedToken =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";

  useEffect(() => {
    const fetchRaces = async () => {
      try {
        setLoading(true);
        const dataRaces = await getRaces();
        const dataClasses = await getClasses();
        setStateContext({ races: dataRaces, classes: dataClasses });
        setRaces(dataRaces);
        setClasses(dataClasses);
        setError(null);
      } catch (err) {
        setError("Failed to fetch races");
      } finally {
        setLoading(false);
      }
    };
    if (storedToken) {
      fetchRaces();
    }
  }, [storedToken]); //TODO

  return (
    <RaceContext.Provider
      value={{ races, loading, error, classes, setStateContext }}
    >
      {children}
    </RaceContext.Provider>
  );
};
export default RaceContext;

export { RaceProvider };
